import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  input,
  model,
} from '@angular/core';

export type ButtonType = 'outline' | 'solid' | 'link';
@Component({
  selector: 'core-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
})
export class ButtonComponent {
  label = model();
  loading = input<boolean>(false);
  type = input<ButtonType>('solid');
  variant = input<'primary' | 'secondary' | 'success' | 'danger' | 'neutral'>(
    'primary'
  );
  disabled = input<boolean>(false);
  className = input<string>('');

  @HostListener('click', ['$event']) clickHandler(e: MouseEvent) {
    if (this.disabled()) {
      console.log('Button is disabled');
      e.preventDefault();
    }
  }

  cdr = inject(ChangeDetectorRef);

  protected getBgColor(): string {
    if (this.disabled()) return 'bg-button-disable';

    if (this.type() === 'outline' || this.type() === 'link') {
      return 'bg-transparent';
    }
    switch (this.variant()) {
      case 'primary':
        return 'bg-button-primary';
      case 'secondary':
        return 'bg-button-secondary';
      case 'success':
        return 'bg-button-success';
      case 'danger':
        return 'bg-button-danger';
      case 'neutral':
        return 'bg-button-neutral';
      default:
        return 'bg-button-primary';
    }
  }

  protected getTextColor() {
    if (this.type() === 'outline' || this.type() === 'link') {
      switch (this.variant()) {
        case 'primary':
          return 'text-button-primary';
        case 'secondary':
          return 'text-button-secondary';
        case 'success':
          return 'text-button-success';
        case 'danger':
          return 'text-button-danger';
        case 'neutral':
          return 'text-button-neutral';
        default:
          return 'text-button-primary';
      }
    }
    return 'text-white';
  }

  protected getBorderColor() {
    if (this.type() === 'outline') {
      switch (this.variant()) {
        case 'primary':
          return 'border-button-primary';
        case 'secondary':
          return 'border-button-secondary';
        case 'success':
          return 'border-button-success';
        case 'danger':
          return 'border-button-danger';
        case 'neutral':
          return 'border-button-neutral';
      }
    }
    return '';
  }

  getClassName() {
    return [this.getBgColor(), this.getTextColor(), this.getBorderColor()].join(
      ' '
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick() {}
}
